<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Referrals/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div v-if="!userSearchStatus" class="position-relative" style="min-height: 60vh">
                <div class="position-absolute " style="top: 50%;transform:translateY(-50%); left: 0;right: 0">
                  <h5 class="text-center mb-2">To create an Referral on the system, start by first inputting the
                    specified email address or phone number.</h5>
                  <div class="row center-div mx-auto">
                    <div class="col-lg-10 col-sm-12 ">
                      <div class="has-icon-left position-relative">
                        <input type="text" id="userSearch" class="form-control" autocomplete="off"
                               v-model="userSearchQuery"
                               placeholder="Type full email address or phone number">
                        <div class="form-control-position"><i class='bx bx-search-alt'></i></div>
                      </div>
                      <div v-if="errors.existingRegisteredReferral" class="text-danger">
                        {{ errors.existingRegisteredReferral }}
                      </div>

                      <div v-if="registeredEmailDomains.length > 0" class="email-domain-list">
                        <div v-for="(registeredEmailDomain, index) in registeredEmailDomains" :key="index">
                          <p @click="margeUserSearchQuery(registeredEmailDomain)" class="mb-0 email-domain-list-item">
                            {{ registeredEmailDomain }}</p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2 mt-lg-0">
                      <button :disabled="userSearchQuery === ''" @click="searchUser" type="button"
                              class="btn btn-block btn-primary">Submit
                      </button>
                    </div>
                  </div>
                  <div v-if="existingRegisteredReferral.id" class="row center-div mx-auto">

                    <div class="col-12 mt-1">
                      <hr>
                      <h5 class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Referral User Already Exist!</span>
                      </h5>
                      <p class="col-12 d-md-flex justify-md-content-start align-items-center">
                        <strong>Referral User Info:</strong>
                        <span>&nbsp;{{ existingRegisteredReferralDetails }}&nbsp;&nbsp;</span>
                        <router-link v-if="existingRegisteredReferral.id"
                                     :to="can('referral-view') ? {name: 'appReferralView', params: {id: existingRegisteredReferral.id }} : '#'"
                                     :title="can('referral-view') ? 'View Referral' : 'View Forbidden'">
                          <span class="glow d-flex align-items-center"><i class='bx bx-show'></i>&nbsp;View</span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <!-- left side numbering steps of the process start. -->
                  <div class="col-lg-2 step-divider">
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 1 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">1
                      </div>
                      <div class="ml-1" :class="tabStep >= 1 ? 'text-primary' : ''">User Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 1 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 2 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">2
                      </div>
                      <div class="ml-1" :class="tabStep >= 2 ? 'text-primary' : ''">Address Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 2 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 3 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">4
                      </div>
                      <div class="ml-1" :class="tabStep >= 3 ? 'text-primary' : ''">Bank Account Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 3 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 4 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">3
                      </div>
                      <div class="ml-1" :class="tabStep >= 4 ? 'text-primary' : ''">Referral Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 4 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 5 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">6
                      </div>
                      <div class="ml-1" :class="tabStep >= 5 ? 'text-primary' : ''">Preview Details</div>
                    </div>
                  </div>
                  <!-- left side numbering steps of the process end. -->
                  <!-- right side response steps of the process starts. -->
                  <div class="col-lg-10 mt-3 mt-lg-0">
                    <div class="row" v-if="tabStep===1">
                      <div class="col-12">
                        <h5>User Information</h5>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>First name *</label>
                          <input v-model="postUserData.first_name" type="text" class="form-control text-capitalize"
                                 placeholder="Enter first name">
                          <div class="text-danger" v-if="errors.user.firstName">{{ errors.user.firstName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Last name *</label>
                          <input v-model="postUserData.last_name" type="text" class="form-control text-capitalize"
                                 placeholder="Enter last name">
                          <div class="text-danger" v-if="errors.user.lastName">{{ errors.user.lastName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Phone Number *</label>
                          <input v-model="postUserData.phone_number" :readonly="userPhoneFilled" max="10" type="tel"
                                 class="form-control " placeholder="ie. 0412345678"
                                 aria-describedby="userAlreadyExistWarning">
                          <small v-if="userFound" id="userAlreadyExistWarning"
                                 class="form-text text-warning d-flex flex-row"><strong><i
                              class='bx bx-info-circle'></i></strong>&nbsp;Previously registered users! Any changes will
                            update this user's data.</small>
                          <div class="text-danger" v-if="errors.user.phoneNumber">{{ errors.user.phoneNumber }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Email Address *</label>
                          <input v-model="postUserData.email" :readonly="userEmailFilled" type="email"
                                 class="form-control" placeholder="ie. example@example.com.au">
                          <div class="text-danger" v-if="errors.user.email">{{ errors.user.email }}</div>
                          <div class="text-danger" v-if="errors.address.userId">{{ errors.address.userId }}</div>
                          <div class="text-danger" v-if="errors.business.userId">{{ errors.business.userId }}</div>
                          <div class="text-danger" v-if="errors.bankAccount.userId">{{
                              errors.bankAccount.userId
                            }}
                          </div>
                          <div class="text-danger" v-if="errors.referral.userId">{{ errors.bankAccount.userId }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Role</label>
                          <VueMultiselect v-model="selectedRole" class="" :options="roleOptions" :close-on-select="true"
                                          placeholder="Select a role" label="name" track-by="name" :show-labels="false"
                                          :allow-empty="false"/>
                          <div class="text-danger" v-if="errors.user.roleId">{{ errors.user.roleId }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button @click="jumpToStart" class="btn btn-light-primary">Prev</button>
                          <button @click="createSingleUser" :disabled="!isPostUserDataFilled"
                                  class="btn btn-light-primary ml-1">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep===2">
                      <div class="col-12">
                        <h5>Address Information</h5>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Address</label>
                          <small v-if="!addressInformationSelectDiv && hasUserAddresses"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to select address from existing one)</small>
                          <small v-if="addressInformationSelectDiv"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to create a new address)</small>
                          <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress"
                                          :options="addressOptions" :close-on-select="true" placeholder="Select Address"
                                          label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                          <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']"
                                                   id="map" classname="form-control"
                                                   placeholder="Please type full address."
                                                   v-on:placechanged="googleMapApiResponseData"/>
                          <div class="text-danger" v-if="errors.business.addressId">{{
                              errors.business.addressId
                            }}
                          </div>
                          <div class="text-danger" v-if="errors.referral.addressId">{{
                              errors.referral.addressId
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData"
                           class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12">
                        <div class="form-group">
                          <label>Street Address *</label>
                          <input v-model="postAddressData.street" type="text" class="form-control"
                                 placeholder="Enter street address"/>
                          <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6 ">
                        <div class="form-group">
                          <label>Suburbs/ Territory *</label>
                          <input v-model="postAddressData.suburb" type="text" class="form-control"
                                 placeholder="Enter suburbs/ territory"/>
                          <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>State *</label>
                          <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="stateList"
                                          :close-on-select="true" placeholder="Select state" label="name"
                                          track-by="value" :show-labels="false"/>
                          <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Post Code *</label>
                          <input v-model="postAddressData.post_code" type="text" class="form-control"
                                 placeholder="Enter code" required/>
                          <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Country *</label>
                          <input v-model="postAddressData.country" type="text" class="form-control" readonly required/>
                          <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv" class="col-12">
                        <div class="form-group">
                          <label>Business Name *</label>
                          <input v-model="postBusinessData.name" type="text" class="form-control"/>
                          <div class="text-danger" v-if="errors.business.name">{{ errors.business.name }}</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button v-if="addressInformationSelectDiv" class="btn btn-light-primary ml-1"
                                  :disabled="!selectedAddress.value" @click="tabNext">Next
                          </button>
                          <button v-else class="btn btn-light-primary ml-1"
                                  :disabled="!hasAddressData || this.postBusinessData.name === ''"
                                  @click="createSingleAddress">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 3">
                      <div class="col-12"><h5>Bank Account Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Bank Name *</label>
                          <input v-model="postBankAccountData.bank_name" type="text" class="form-control "
                                 placeholder="Enter bank name.">
                          <div class="text-danger" v-if="errors.bankAccount.bankName">{{
                              errors.bankAccount.bankName
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Name *</label>
                          <input v-model="postBankAccountData.account_name" type="text" class="form-control "
                                 placeholder="Enter account name.">
                          <div class="text-danger" v-if="errors.bankAccount.accountName">
                            {{ errors.bankAccount.accountName }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Number *</label>
                          <input v-model="postBankAccountData.account_number" type="text" class="form-control "
                                 placeholder="Enter account number.">
                          <div class="text-danger" v-if="errors.bankAccount.accountNumber">
                            {{ errors.bankAccount.accountNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>BSB *</label>
                          <input v-model="postBankAccountData.bsb" type="text" class="form-control "
                                 placeholder="Enter BSB.">
                          <div class="text-danger" v-if="errors.bankAccount.bsb">{{ errors.bankAccount.bsb }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostBankAccountDataFilled"
                                  @click="existingRegisteredBankAccountDataHasChanged? createSingleBankAccount() : tabNext()">
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep ===4">
                      <div class="col-12"><h5>Additional Information</h5></div>

                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Percentage on appointment *</label>
                          <input v-model="postReferralData.commission_rate" min="0.00" step="0.001" type="number"
                                 class="form-control">
                          <div class="text-danger" v-if="errors.referral.commissionRate">
                            {{ errors.referral.commissionRate }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Code *</label>
                          <input v-model="postReferralData.code" type="text"
                                 class="form-control">
                          <div class="text-danger" v-if="errors.referral.code">
                            {{ errors.referral.code }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Company Name *</label>
                          <input v-model="postReferralData.company_name" type="text"
                                 class="form-control">
                          <div class="text-danger" v-if="errors.referral.companyName">
                            {{ errors.referral.companyName }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12"><h5>ABN Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>ABN Number *</label>
                          <input v-model="postReferralData.abn" type="text" class="form-control "
                                 placeholder="Enter ABN">
                          <div class="text-danger" v-if="errors.referral.abn">{{ errors.referral.abn }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostReferralField"
                                  @click="tabNext">Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="tabStep ===5">
                      <div class="col-12"><h3>Preview Details</h3></div>
                      <div class="col-12">
                        <table class="table table-responsive table-borderless table-transparent">
                          <tbody>
                          <tr>
                            <td class="bold">First Name</td>
                            <td>:&nbsp;{{ postUserData.first_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Last Name</td>
                            <td>:&nbsp;{{ postUserData.last_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Phone Number</td>
                            <td :class="postUserData.phone_number ? '' : 'text-danger'">
                              :&nbsp;{{ postUserData.phone_number ? postUserData.phone_number : 'Not Provided' }}
                            </td>
                          </tr>
                          <tr>
                            <td class="bold">Email</td>
                            <td :class="postUserData.email ? '' : 'text-danger'">
                              :&nbsp;{{ postUserData.email ? postUserData.email : 'Not Provided' }}
                            </td>
                          </tr>
                          <tr>
                            <td class="bold">Referral User Role</td>
                            <td>:&nbsp;{{ selectedRole.name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Address</td>
                            <td>:&nbsp;{{ addressInformationSelectDiv ? selectedAddress.name : filledAddress }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Name</td>
                            <td>:&nbsp;{{ postBankAccountData.bank_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account Name</td>
                            <td>:&nbsp;{{ postBankAccountData.account_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account Number</td>
                            <td>:&nbsp;{{ postBankAccountData.account_number }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account BSB</td>
                            <td>:&nbsp;{{ postBankAccountData.bsb }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Percentage on appointment</td>
                            <td>:&nbsp;{{ postReferralData.commission_rate }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Code</td>
                            <td>:&nbsp;{{ postReferralData.code }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Company Name</td>
                            <td>:&nbsp;{{ postReferralData.company_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">ABN Number</td>
                            <td>:&nbsp;{{ postReferralData.abn }}</td>
                          </tr>
                          </tbody>
                        </table>
                        <div class="text-danger" v-if="errors.referral.status">{{ errors.referral.status }}</div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="createSingleReferral()">Create Referral
                            User
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- right side response steps of the process end. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueMultiselect from 'vue-multiselect';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "ReferralCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VueGoogleAutocomplete,
    VueMultiselect,

  },

  data() {
    return {
      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },

      getRolesParams: {
        type: 10, // 10 for referral type
      },

      getUsersParams: {
        email_phone_where_has_user_additional_infos_query: '',
        with_relation: ['role', 'addresses.business', 'bankAccount', 'referral'],
      },

      userSearchStatus: false,
      userSearchQuery: '',
      userSearchEmailDomainPartQuery: '',
      userFound: false,
      userEmailFilled: false,
      userPhoneFilled: false,
      tabStep: 1,
      userId: null,
      addressInformationSelectDiv: false,
      hasUserAddresses: false,

      existingRegisteredReferral: {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },

      postUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      existingRegisteredUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      selectedRole: {
        value: '',
        name: 'None'
      },

      selectedAddress: {
        value: '',
        name: 'None'
      },

      postAddressData: {
        user_id: '',
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      selectedState: {
        value: '',
        name: 'None'
      },

      postBusinessData: {
        address_id: '',
        user_id: '',
        name: '',
      },

      postBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',

      },

      existingRegisteredBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',
      },

      postReferralData: {
        user_id: '',
        address_id: '',
        commission_rate: '',
        commission_type: 2, // 1: fixed, 2: percentage
        abn: '',
        code: '',
        company_name: '',
        status: 1,
      },
      errors: {
        existingRegisteredReferral: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        business: {
          userId: '',
          addressId: '',
          name: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        referral: {
          userId: '',
          addressId: '',
          commissionRate: '',
          code: '',
          companyName: '',
          abn: '',
          status: '',
        },
      },
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      users: 'appUsers/users',
      registeredEmailDomains: 'appUsers/registeredEmailDomains'
    }),
    redirectRouteName() {
      return this.can('referral-view-any') ? 'appReferralList' : 'appReferralCreate';
    },

    registeredEmailDomains() {

      let registeredEmailDomains = this.$store.getters['appUsers/registeredEmailDomains'].email_domains;

      if (this.userSearchEmailDomainPartQuery !== '' && Array.isArray(registeredEmailDomains)) {
        return this.$store.getters['appUsers/registeredEmailDomains'].email_domains.filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      if (this.userSearchEmailDomainPartQuery !== '' && (typeof registeredEmailDomains) === 'object') {
        return Object.values(registeredEmailDomains).filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      return [];
    },

    existingRegisteredReferralDetails() {
      return `${this.existingRegisteredReferral.firstName} ${this.existingRegisteredReferral.lastName} (${this.existingRegisteredReferral.email} | ${this.existingRegisteredReferral.phoneNumber})`;
    },
    isPostUserDataFilled() {
      return !!(this.postUserData.first_name && this.postUserData.last_name && (this.postUserData.email || this.postUserData.phone_number) && this.postUserData.role_id !== '');
    },

    addressOptions() {
      let userAddresses = this.users && this.users[0] && this.users[0].addresses
          ? this.users[0].addresses.filter((address) => address.business && address.business.id).map((address) => {
            let businessName = address.business.name ? address.business.name : '';
            let addressId = address.id;
            let addressStreet = address.street;
            let addressSuburb = address.suburb;
            let addressState = address.state.toUpperCase();
            let addressPostCode = address.post_code;
            let addressCountry = address.country;

            let fullAddress = `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;

            if (businessName) {
              fullAddress = `${businessName} - ${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
            }

            return {
              value: addressId,
              name: fullAddress,
            };
          })
          : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...userAddresses
      ];
    },

    existingRegisteredUserDataHasChanged() {
      return this.postUserData.first_name !== this.existingRegisteredUserData.first_name ||
          this.postUserData.last_name !== this.existingRegisteredUserData.last_name ||
          this.postUserData.email !== this.existingRegisteredUserData.email ||
          this.postUserData.phone_number !== this.existingRegisteredUserData.phone_number;
    },

    roleOptions() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });

      return [{
        value: '',
        name: 'None'
      }, ...activeRoles];
    },

    stateList() {
      return [...this.$store.getters['appSettings/settingDefaultState'].value];
    },

    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },

    isPostBankAccountDataFilled() {
      return !!(this.postBankAccountData.bank_name && this.postBankAccountData.account_name && this.postBankAccountData.account_number && this.postBankAccountData.bsb);
    },

    filledAddress() {
      let businessName = this.postBusinessData.name;
      let addressStreet = this.postAddressData.street;
      let addressSuburb = this.postAddressData.suburb;
      let addressState = this.postAddressData.state.toUpperCase();
      let addressPostCode = this.postAddressData.post_code;
      let addressCountry = this.postAddressData.country;

      return businessName
          ? `${businessName} - ${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
          : `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    existingRegisteredBankAccountDataHasChanged() {
      return this.postBankAccountData.user_id !== this.existingRegisteredBankAccountData.user_id ||
          this.postBankAccountData.bank_name !== this.existingRegisteredBankAccountData.bank_name ||
          this.postBankAccountData.account_name !== this.existingRegisteredBankAccountData.account_name ||
          this.postBankAccountData.account_number !== this.existingRegisteredBankAccountData.account_number ||
          this.postBankAccountData.bsb !== this.existingRegisteredBankAccountData.bsb;
    },

    isPostReferralField() {
      return !!(this.postReferralData.commission_rate && this.postReferralData.code && this.postReferralData.company_name && this.postReferralData.abn);
    }
  },

  watch: {
    userSearchQuery(userSearchQuery) {
      this.userSearchEmailDomainPartQuery = userSearchQuery.includes('@') ? userSearchQuery.substring(userSearchQuery.lastIndexOf('@') + 1) : '';
    },

    selectedRole(selectedRole) {
      this.postUserData.role_id = selectedRole.value;
    },


    selectedAddress(selectedAddress) {
      this.postBusinessData.address_id = selectedAddress.value;
      this.postReferralData.address_id = selectedAddress.value;
    },

    selectedState(selectedState) {
      this.postAddressData.state = selectedState.value;
    },

  },

  methods: {
    ...mapActions({
      resetUser: 'appUsers/resetUser',
      resetUsers: 'appUsers/resetUsers',
      resetRoles: 'appRoles/resetRoles',
      resetAddress: 'appAddresses/resetAddress',
      resetAddresses: 'appAddresses/resetAddresses',
      resetBusiness: 'appBusinesses/resetBusiness',
      resetBankAccount: 'appBankAccounts/resetBankAccount',
      resetReferral: 'appReferrals/resetReferral',

      postUser: 'appUsers/postUser',
      putUser: 'appUsers/putUser',
      postAddress: 'appAddresses/postAddress',
      postBusiness: 'appBusinesses/postBusiness',
      postBankAccount: 'appBankAccounts/postBankAccount',
      postReferral: 'appReferrals/postReferral',

      getSettings: 'appSettings/getSettings',
      getUsers: 'appUsers/getUsers',
      getRoles: 'appRoles/getRoles',
      getRegisteredEmailDomains: 'appUsers/getRegisteredEmailDomains',
    }),

    async margeUserSearchQuery(registeredEmailDomain) {
      let firstPart = this.userSearchQuery.substring(0, this.userSearchQuery.lastIndexOf('@'));
      this.userSearchQuery = `${firstPart}@${registeredEmailDomain}`;
      await this.searchUser();
      this.userSearchEmailDomainPartQuery = '';
    },

    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async searchUser() {
      this.loader(true);
      this.userSearchEmailDomainPartQuery = '';

      this.getUsersParams.email_phone_where_has_user_additional_infos_query = this.userSearchQuery;
      await this.getUsers(this.getUsersParams);
      if (this.users.length > 0) {

        let user = this.users[0];

        if (user && user.referral && user.referral.id) {
          await this.setExistingRegisteredReferralError();
          await this.setExistingRegisteredReferral(user, user.referral.id);
          this.loader(false);
          return;
        }

        await this.resetExistingRegisteredReferralError();
        await this.resetExistingRegisteredReferral();

        this.userFound = true;

        this.userId = user.id;

        this.postAddressData.user_id = this.userId;
        this.postBusinessData.user_id = this.userId;
        this.postBankAccountData.user_id = this.userId;
        this.postReferralData.user_id = this.userId;

        await this.setPostUserData(user);
        await this.setExistingRegisteredUserData(user);

        if (user.role) {
          this.selectedRole.value = user.role.id;
          this.selectedRole.name = user.role.name;
        }

        if (user.bankAccount) {
          await this.setPostBankAccountData(user.bankAccount);
          await this.setExistingRegisteredBankAccountData(user.bankAccount);
        }
      }

      if (this.users.length <= 0) {
        if (await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.email = this.userSearchQuery;
          this.userEmailFilled = true;
        }

        if (!await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.phone_number = this.userSearchQuery;
          this.userPhoneFilled = true;
        }
      }

      if (this.addressOptions.length > 1) {
        this.addressInformationSelectDiv = true;
        this.hasUserAddresses = true;
      }

      this.userSearchStatus = true;

      this.loader(false);
    },


    async jumpToStart() {
      this.userSearchStatus = false;
      this.userFound = false;
      this.userEmailFilled = false;
      this.userPhoneFilled = false;
      this.tabStep = 1;
      this.userId = null;

      await this.resetUsers();
      await this.resetUser();
      await this.resetPostUserData();
      await this.resetSelectedRole();
      await this.resetSelectedAddress();
      await this.resetErrors();

      this.addressInformationSelectDiv = false;
      this.hasUserAddresses = false;

    },


    async setExistingRegisteredReferral(user, referralId) {
      this.existingRegisteredReferral = {
        id: referralId,
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
      };
    },

    async resetExistingRegisteredReferral() {
      this.existingRegisteredReferral = {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      };
    },


    async setExistingRegisteredReferralError() {
      this.errors.existingRegisteredReferral = 'The user is already registered as a Referral.';
    },

    async resetExistingRegisteredReferralError() {
      this.errors.existingRegisteredReferral = '';
    },

    async setExistingRegisteredUserData(user) {
      this.existingRegisteredUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.existingRegisteredUserData.first_name = user.first_name ?? '';
      this.existingRegisteredUserData.last_name = user.last_name ?? '';
      this.existingRegisteredUserData.email = user.email ?? '';
      this.existingRegisteredUserData.phone_number = user.phone_number ?? '';
    },

    async setPostUserData(user) {
      this.postUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.postUserData.first_name = user.first_name ?? '';
      this.postUserData.last_name = user.last_name ?? '';
      this.postUserData.email = user.email ?? '';
      this.postUserData.phone_number = user.phone_number ?? '';
    },

    async resetPostUserData() {
      this.postUserData = {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
      };
    },

    async resetSelectedRole() {
      this.selectedRole = {
        value: '',
        name: 'None'
      };
    },

    async createSingleUser() {
      if (!this.userId) {
        await this.loader(true);
        await this.postUser(this.postUserData).then(async (response) => {
          await this.loader(false);
          if (response.status === 200 || response.status === 201) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.userId = user.id;
            this.postAddressData.user_id = this.userId;
            this.postBusinessData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postReferralData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }
        });
        return;
      }

      if (this.userId && this.existingRegisteredUserDataHasChanged) {
        let dataObj = {
          id: this.userId,
          data: this.postUserData,
        };
        await this.loader(true);
        await this.putUser(dataObj).then(async (response) => {
          await this.loader(false);
          if (response.status === 200) {

            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.postAddressData.user_id = this.userId;
            this.postBusinessData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postReferralData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';

          this.showToastMessage(response);
        });
        return;
      }

      if (this.userId && !this.existingRegisteredUserDataHasChanged) {
        await this.tabNext();
      }
    },

    async resetSelectedAddress() {
      this.selectedAddress = {
        value: '',
        name: 'None'
      };
    },

    async setPostBankAccountData(bankAccount) {
      this.postBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.postBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.postBankAccountData.account_name = bankAccount.account_name ?? '';
      this.postBankAccountData.account_number = bankAccount.account_number ?? '';
      this.postBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async setExistingRegisteredBankAccountData(bankAccount) {
      this.existingRegisteredBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.existingRegisteredBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.existingRegisteredBankAccountData.account_name = bankAccount.account_name ?? '';
      this.existingRegisteredBankAccountData.account_number = bankAccount.account_number ?? '';
      this.existingRegisteredBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async resetErrors() {
      this.errors = {
        existingRegisteredReferral: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        business: {
          userId: '',
          addressId: '',
          name: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        referral: {
          userId: '',
          addressId: '',
          commissionRate: '',
          code: '',
          companyName: '',
          abn: '',
          status: '',
        },

      }
    },

    async validateEmailString(string) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      this.loader(true);
      let streetNumber = googleMapApiResponseData.street_number ?? '';
      let streetName = googleMapApiResponseData.route ?? '';
      let suburbs = googleMapApiResponseData.locality ?? '';
      let state = googleMapApiResponseData.administrative_area_level_1 ?? '';
      let postCode = googleMapApiResponseData.postal_code ?? '';
      let country = googleMapApiResponseData.country ?? '';

      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.stateList.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.stateList[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }
      this.loader(false);
    },

    async createSingleAddress() {
      this.loader(true);
      await this.postAddress(this.postAddressData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let address = this.$store.getters['appAddresses/address'];

          this.postBusinessData.address_id = address.id;
          this.postReferralData.address_id = address.id;

          await this.createSingleBusiness().then(async () => {
            if (this.errors.business.userId || this.errors.business.name || this.errors.business.addressId) {
              this.loader(false);
            }
          });

          if (this.errors.business.userId) {
            await this.tabJump(1);
            return;
          }
          if (this.errors.business.addressId || this.errors.business.name) {
            await this.tabJump(2);
            return;
          }

          let business = this.$store.getters['appBusinesses/business'];

          let newAddress = business.name
              ? {
                id: address.id,
                name: `${business.name} - ${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
              }
              : {
                id: address.id,
                name: `${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
              };

          this.addressOptions.push(newAddress);
          this.hasUserAddresses = true;

          await this.tabNext();
        }

        this.errors.address.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.address.userId) {
          await this.tabJump(1);
        }

      });
      this.loader(false);
    },

    async createSingleBusiness() {
      await this.postBusiness(this.postBusinessData).then(async (response) => {

        this.errors.business.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        this.errors.business.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
        this.errors.business.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async createSingleBankAccount() {
      this.loader(true);
      await this.postBankAccount(this.postBankAccountData).then(async (response) => {
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          await this.tabNext();
        }

        this.errors.bankAccount.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.bankAccount.accountName = response.errors && response.errors.accountName ? response.errors.accountName[0] : '';
        this.errors.bankAccount.accountNumber = response.errors && response.errors.accountNumber ? response.errors.accountNumber[0] : '';
        this.errors.bankAccount.bsb = response.errors && response.errors.bsb ? response.errors.bsb[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.bankAccount.userId) {
          await this.tabJump(1);
        }
      });
    },

    async createSingleReferral() {
      this.loader(true);
      await this.postReferral(this.postReferralData).then(async (response) => {
        await this.loader(false);
        if (response.status === 201) {
          const toastObj = {
            message: 'Referral created successfully.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.referral.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.referral.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
        this.errors.referral.commissionRate = response.errors && response.errors.commission_rate ? response.errors.commission_rate[0] : '';
        this.errors.referral.operationPercentage = response.errors && response.errors.operation_percentage ? response.errors.operation_percentage[0] : '';
        this.errors.referral.marketingPercentage = response.errors && response.errors.marketing_percentage ? response.errors.marketing_percentage[0] : '';
        this.errors.referral.technicianLimit = response.errors && response.errors.technician_limit ? response.errors.technician_limit[0] : '';
        this.errors.referral.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';
        this.errors.referral.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.referral.userId) {
          await this.tabJump(1);
        }

        if (this.errors.referral.addressId) {
          await this.tabJump(2);
        }

        if (this.errors.referral.commissionRate || this.errors.referral.abn) {
          await this.tabJump(4);
        }
      });
    },


    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRolesList() {
      await this.getRoles(this.getRolesParams);
    },


    async getRegisterEmailDomainList() {
      await this.getRegisteredEmailDomains();
    },
  },

  async mounted() {
    await this.getSettingList();
    await this.getRolesList();
    await this.getRegisterEmailDomainList();
  },

  async beforeMount() {
    await this.resetUser();
    await this.resetUsers();
    await this.resetRoles();
    await this.resetAddress();
    await this.resetAddresses();
    await this.resetReferral();
  }
}
</script>

<style scoped>
.center-div {
  width: 85%;
}

.step-number {
  width: 38px;
  height: 38px;
  color: white;
  border-radius: 0.375rem
}

.step-line {
  width: 38px;
  height: 38px;

}

.inner-line {
  display: inline-block !important;
  /* border-left: 1px solid #5a8dee; */
  height: 100%;
}


.step-divider {
  border-right: 1px solid #d4d8dd;
}

.email-domain-list {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 1rem;
  background-color: gray;
  max-height: 217px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #d2ddec;
  border-right: 1px solid #d2ddec;
  border-bottom: 1px solid #d2ddec;
  z-index: 30;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
}

.email-domain-list-item {
  padding-left: 1rem;
  cursor: pointer;
  margin: 2px 0;
  color: black;
}

.email-domain-list-item:hover {
  background-color: #5a8dee;
  color: white;

}

@media only screen and (max-width: 600px) {
  .center-div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .step-divider {
    border-right: none;
    border-bottom: 1px solid #d4d8dd;
    padding-bottom: 27px;
  }
}
</style>